'use client'

import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Checkbox from '@mui/material/Checkbox'
import FormControlLabel from '@mui/material/FormControlLabel'
import Grid from '@mui/material/Grid'
import Link from '@mui/material/Link'
import TextField from '@mui/material/TextField'
import {useRouter} from 'next/navigation'
import {useState} from 'react'

import {handleSubmit} from './actions'

export const LoginForm = () => {
	const router = useRouter()
	const [isDisabled, setDisabled] = useState<boolean>(() => false)
	return (
		<Box
			component="form"
			action={async (FormData) => {
				setDisabled(() => true)
				const result = await handleSubmit(FormData)
				setDisabled(() => false)
				if (result) router.replace('/')
			}}
			sx={{mt: 1}}
		>
			<TextField
				type="email"
				margin="normal"
				required
				fullWidth
				id="email"
				label="Логин (Email)"
				name="email"
				autoComplete="email"
				autoFocus
			/>
			<TextField
				margin="normal"
				required
				fullWidth
				name="password"
				label="Пароль"
				type="password"
				id="password"
				autoComplete="current-password"
			/>
			<FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Запомнить" />
			<Button type="submit" fullWidth variant="contained" sx={{mt: 3, mb: 2}} disabled={isDisabled}>
				Вход
			</Button>
			<Grid container>
				<Grid item xs>
					<Link href="#" variant="body2">
						Забыли пароль?
					</Link>
				</Grid>
				<Grid item>
					<Link href="#" variant="body2">
						Создать аккаунт
					</Link>
				</Grid>
			</Grid>
		</Box>
	)
}
